import React from "react";
import user from "../../Image/user.png";

const ViewModal = ({ item }) => {
  return (
    <div
      className="modal fade"
      id="viewUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              View User Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <div>
                <div className="mb-3">
                  <label className="mb-2">Profile Image</label>
                  <div className="profile-img">
                    <img
                      src={item?.profile_image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = user;
                      }}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="mb-2">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.username}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={item?.mobilenumber}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="mail"
                    className="form-control"
                    placeholder={item?.email}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Refer Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item?.refercode}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Mobile Verification</label>
                  <div>
                    {item?.mobilenumberStatus == "Verify" ? (
                      <p class="badge bg-success me-1 text-white mb-0">
                        Verified
                      </p>
                    ) : (
                      <p class="badge bg-warning me-1 text-white mb-0">
                        Pending
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="mb-2">Email Verification</label>
                  <div>
                    {item?.emailStatus == "Verify" ? (
                      <p class="badge bg-success me-1 text-white mb-0">
                        Verified
                      </p>
                    ) : (
                      <p class="badge bg-warning me-1 text-white mb-0">
                        Pending
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;
