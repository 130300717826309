import React from "react";
import { Link } from "react-router-dom";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({});

const Pagination = ({ currentPage, nPage, handleChangePage = () => {} }) => {
  const { items } = usePagination({
    count: nPage.length,
    hideNextButton: true,
    hidePrevButton: true,
    onChange: (e) => {
      handleChangePage(e.target.value);
    },
  });

  return (
    <div className="pro-add-new px-0 mb-0 pt-3">
      <p>
        1 - {nPage?.length} of {currentPage}
      </p>

      <nav aria-label="...">
        <List className="pagination pagination-sm mb-0">
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = <button type="button">...</button>;
            } else {
              children = (
                <button
                  type="button"
                  {...item}
                  value={page}
                  className={`${
                    (selected || currentPage == page) && "active-link"
                  }`}
                >
                  {page}
                </button>
              );
            }

            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </div>
  );
};

export default Pagination;
