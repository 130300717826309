import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import { GetUsersApi, POSTAPI, RemoveUser } from "../helper/integration";
import ViewModal from "../common/user/ViewModal";
import Paggination from "../common/Paggination";
import EditUser from "../common/user/EditUser";
import toast from "react-hot-toast";
import NotFound from "../Image/notfound.png";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { Api } from "../utils/Api";

const UserDetails = () => {
  const [isLoad, setisLoad] = useState(false);
  const [FilterData, setFilterData] = useState([]);
  const [MainUser, setMainUser] = useState([]);
  const [users, setusers] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [isInsert, setisInsert] = useState(false);
  const [ImportData, setImportData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [FilterType, setFilterType] = useState("");
  const [SingleData, setSingleData] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords;
    if (FilterData?.length) {
      currentRecords = FilterData.slice(indexOfFirstRecord, indexOfLastRecord);
    } else {
      currentRecords = MainUser.slice(indexOfFirstRecord, indexOfLastRecord);
    }
    setusers(currentRecords);
  };

  const handleImportData = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function (result) {
          const headerRow = result.data[0];
          const rowsWithData = result.data.slice(1);
          const formattedRows = rowsWithData.map((row) => {
            const formattedRow = {};
            headerRow.forEach((header, index) => {
              formattedRow[header] = row[index];
            });
            return formattedRow;
          });

          const formData = new FormData();
          formattedRows.forEach((row, rowIndex) => {
            Object.keys(row).forEach((key) => {
              const value = row[key];
              if (key.toLowerCase().includes("file") && value) {
                const file = new File([value], value, { type: "image/png" }); // Adjust MIME type as needed
                formData.append(`file_${rowIndex}_${key}`, file);
              } else {
                formData.append(`data_${rowIndex}_${key}`, value);
              }
            });
          });

          setImportData((prev) => formattedRows);
          document.getElementById("OpenImportModal").click();
          // toastss
          //   .promise(POSTAPI(Api.AddUserCSV, { data: formattedRows }), {
          //     loading: "Wait Few Moment !",
          //     success: "Users Add Successfully !",
          //     error: "Somthing Went Wrong !",
          //   })
          //   .then(() => {
          //     setisUpdate(!isUpdate);
          //   })
          //   .catch(() => {});
        },
        header: false, // Assuming no header row in the CSV
      });
    }
    e.target.files = null;
  };

  const HandleFilterWithType = (e) => {
    let { value } = e.target;
    setFilterType(value);
    if (value == "Verify") {
      let data = MainUser.filter(
        (item) =>
          item?.mobilenumberStatus == "Verify" && item?.emailStatus == "Verify"
      );
      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setusers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } else if (value == "new") {
      const newDates = MainUser.filter((item) => item?.createdAt);
      let TodayDate = new Date();
      const data = newDates.filter((el) => {
        if (el?.createdAt) {
          let RecordDate = new Date(el?.createdAt);
          if (
            RecordDate?.toLocaleDateString() == TodayDate?.toLocaleDateString()
          )
            return el;
        }
      });

      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setusers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } else if (value == "Deactive") {
      let data = MainUser.filter(
        (item) =>
          item?.mobilenumberStatus == "Deactive" &&
          item?.emailStatus == "Deactive"
      );
      setFilterData(data);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
      setcurrentPage(1);
      setusers(currentRecords);
      let nPages = Math.ceil(data?.length / recordsPerPage);
      console.log(nPages);
      let num = [...Array(nPages + 1).keys()].slice(1);
      console.log(users);
      setnPage(num);
    } else {
      setFilterType("");
      setFilterData([]);
      let indexOfLastRecord = 1 * recordsPerPage;
      let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      let currentRecords = MainUser.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setcurrentPage(1);
      setusers(currentRecords);
      let nPages = Math.ceil(MainUser?.length / recordsPerPage);
      let num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    }
  };

  // const handleDeleteRecord = (id) => {
  //   if (window.confirm("Are you sure you want to delete user ?")) {
  //     let obj = {
  //       user_id: id,
  //     };
  //     toast
  //       .promise(RemoveUser(obj), {
  //         loading: "Wait Few Moment !",
  //         success: "User Delete Successfully !",
  //         error: "Somethig Wrong !",
  //       })
  //       .then(() => {
  //         setisUpdate(!isUpdate);
  //       })
  //       .catch(() => {});
  //   }
  // };

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray;
    if (FilterData?.length) {
      newArray = FilterData.filter(
        (item) =>
          item.username.toLowerCase().includes(val.toLowerCase()) ||
          item.email.toLowerCase().includes(val.toLowerCase()) ||
          item.mobilenumber.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      newArray = MainUser.filter(
        (item) =>
          item.username.toLowerCase().includes(val.toLowerCase()) ||
          item.email.toLowerCase().includes(val.toLowerCase()) ||
          item.mobilenumber.toLowerCase().includes(val.toLowerCase())
      );
    }
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setusers(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await GetUsersApi();
        setMainUser(data);
        if (FilterType) {
          if (FilterType == "Verify") {
            let newData = data.filter(
              (item) =>
                item?.mobilenumberStatus == "Verify" &&
                item?.emailStatus == "Verify"
            );
            setFilterData(newData);
            let indexOfLastRecord = 1 * recordsPerPage;
            let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
            let currentRecords = newData.slice(
              indexOfFirstRecord,
              indexOfLastRecord
            );
            setcurrentPage(1);
            setusers(currentRecords);
            let nPages = Math.ceil(newData?.length / recordsPerPage);
            let num = [...Array(nPages + 1).keys()].slice(1);
            setnPage(num);
          } else if (FilterType == "new") {
            const newDates = data.filter((item) => item?.createdAt);
            let TodayDate = new Date();
            let newdata = newDates.filter((el) => {
              if (el?.createdAt) {
                let RecordDate = new Date(el?.createdAt);
                if (
                  RecordDate?.toLocaleDateString() ==
                  TodayDate?.toLocaleDateString()
                )
                  return el;
              }
            });

            setFilterData(newdata);
            let indexOfLastRecord = 1 * recordsPerPage;
            let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
            let currentRecords = newdata?.slice(
              indexOfFirstRecord,
              indexOfLastRecord
            );
            setcurrentPage(1);
            setusers(currentRecords);
            let nPages = Math.ceil(newdata?.length / recordsPerPage);
            let num = [...Array(nPages + 1).keys()].slice(1);
            setnPage(num);
          } else if (FilterType == "Deactive") {
            let newData = data.filter(
              (item) =>
                item?.mobilenumberStatus == "Deactive" &&
                item?.emailStatus == "Deactive"
            );
            setFilterData(newData);
            let indexOfLastRecord = 1 * recordsPerPage;
            let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
            let currentRecords = newData.slice(
              indexOfFirstRecord,
              indexOfLastRecord
            );
            setcurrentPage(1);
            setusers(currentRecords);
            let nPages = Math.ceil(newData?.length / recordsPerPage);
            console.log(nPages);
            let num = [...Array(nPages + 1).keys()].slice(1);
            setnPage(num);
          } else {
            setFilterType("");
            setFilterData([]);
            let indexOfLastRecord = 1 * recordsPerPage;
            let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
            let currentRecords = data.slice(
              indexOfFirstRecord,
              indexOfLastRecord
            );
            setcurrentPage(1);
            setusers(currentRecords);
            let nPages = Math.ceil(data?.length / recordsPerPage);
            let num = [...Array(nPages + 1).keys()].slice(1);
            setnPage(num);
          }
        } else {
          let indexOfLastRecord = currentPage * recordsPerPage;
          let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
          let currentRecords = data.slice(
            indexOfFirstRecord,
            indexOfLastRecord
          );
          setusers(currentRecords);
          let nPages = Math.ceil(data?.length / recordsPerPage);
          let num = [...Array(nPages + 1).keys()].slice(1);
          setnPage(num);
        }
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  const confirmDelete = () => {
    if (userIdToDelete) {
      let obj = {
        user_id: userIdToDelete,
      };
      toast
        .promise(RemoveUser(obj), {
          loading: "Wait Few Moment !",
          success: "User Delete Successfully !",
          error: "Something Went Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
          setUserIdToDelete(null);
        })
        .catch(() => {});
    }
  };

  // useEffect(() => {
  //   const confirmDeleteButton = document.getElementById("confirmDeleteButton");
  //   if (confirmDeleteButton) {
  //     confirmDeleteButton.addEventListener("click", confirmDelete);
  //   }

  //   return () => {
  //     if (confirmDeleteButton) {
  //       confirmDeleteButton.removeEventListener("click", confirmDelete);
  //     }
  //   };
  // }, [userIdToDelete]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>USER DETAILS</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="mb-3 col-lg-2 col-md-3 col-5">
                    <label className="mb-2">Filter</label>
                    <select
                      className="form-select form-control"
                      id=""
                      aria-label="Floating label select example"
                      onChange={HandleFilterWithType}
                    >
                      <option selected>All </option>
                      <option value="new">New User</option>
                      <option value="Verify">Verified </option>
                      <option value="Deactive">Pending</option>
                    </select>
                  </div>
                  <div
                    class="mb-3 btn-group"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <Link
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exportUserDetail"
                      class="btn btn-outline-primary shadow-none"
                    >
                      EXPORT CSV
                    </Link>
                    <Link
                      type="button"
                      data-bs-toggle="modal"
                      id="OpenImportModal"
                      data-bs-target="#importUserDetail"
                      class="btn btn-outline-primary shadow-none"
                      hidden
                    >
                      export
                    </Link>
                    <CSVLink
                      data={MainUser}
                      filename={"Users.csv"}
                      type="button"
                      id="exportUser"
                      class="btn btn-outline-primary shadow-none"
                      hidden
                    >
                      EXPORT CSV
                    </CSVLink>
                    <input
                      type="file"
                      id="importUser"
                      onChange={handleImportData}
                      hidden
                    />
                    <label htmlFor="importUser">
                      <div class="btn btn-outline-primary shadow-none   ">
                        IMPORT CSV
                      </div>
                    </label>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Profile Image</th>
                        <th>User Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Refer Code</th>
                        <th>Mobile Verification</th>
                        <th>Email Verification</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={9}>
                            <div
                              className="d-flex justify-content-center align-items-center  "
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : !users?.length ? (
                        <tr>
                          <td colSpan={9}>
                            <div
                              className="d-flex flex-column  justify-content-center align-items-center  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div>User not found !</div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        users.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {currentPage == 1
                                  ? index + 1
                                  : index + 1 + currentPage * 10 - 10}
                              </td>
                              <td>
                                <div className="profile-img">
                                  <img
                                    src={item?.profile_image}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user;
                                    }}
                                    className="img-fluid"
                                  />
                                </div>
                              </td>
                              <td>{item?.username}</td>
                              <td>{item?.mobilenumber}</td>
                              <td>{item?.email}</td>
                              <td>{item?.refercode}</td>
                              <td>
                                <div className="d-flex justify-content-center ">
                                  {item?.mobilenumberStatus == "Verify" ? (
                                    <Link
                                      to={"#"}
                                      class="badge bg-success me-1 text-white"
                                    >
                                      Verified
                                    </Link>
                                  ) : (
                                    <Link
                                      to={"#"}
                                      class="badge bg-warning me-1 text-white"
                                    >
                                      Pending
                                    </Link>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center ">
                                  {item?.emailStatus == "Verify" ? (
                                    <Link
                                      to={"#"}
                                      class="badge bg-success me-1 text-white"
                                    >
                                      Verified
                                    </Link>
                                  ) : (
                                    <Link
                                      to={"#"}
                                      class="badge bg-warning me-1 text-white"
                                    >
                                      Pending
                                    </Link>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    onClick={() => {
                                      setSingleData((prev) => item);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  {/* <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleDeleteRecord(item?._id);
                                    }}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </span> */}
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setUserIdToDelete(item._id);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmationModal"
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </span>
                                  <Link
                                    onClick={() => {
                                      setSingleData((prev) => item);
                                    }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewUser"
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}

                      {/**/}
                    </tbody>
                  </table>
                </div>
                <Paggination
                  currentPage={currentPage}
                  nPage={nPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Edit Modal --> */}
        <EditUser
          item={SingleData}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          setItem={setSingleData}
        />
        {/* <!--End Edit Modal --> */}

        {/* <!-- View Modal --> */}
        <ViewModal item={SingleData} />
        {/* <!--End View Modal --> */}

        {/* Export CSV */}
        <div
          className="modal fade"
          id="importUserDetail"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Import User Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <div>
                    <div>
                      <h4 className="py-4 mx-auto ">
                        Do You Want Add Duplicate Data ?{" "}
                      </h4>
                      <p>Total Data : {MainUser.length}</p>
                      <p>
                        Duplicate Data :{" "}
                        {
                          ImportData?.filter((el) =>
                            MainUser?.some((item) => item?._id == el?._id)
                          ).length
                        }
                      </p>
                      <p>
                        New Data :{" "}
                        {
                          ImportData?.filter(
                            (el) =>
                              !MainUser?.some((item) => item?._id == el?._id)
                          ).length
                        }
                      </p>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button
                        onClick={() => {
                          let newData = ImportData?.filter(
                            (el) =>
                              !MainUser?.some((item) => item?._id == el?._id)
                          );
                          if (!newData?.length)
                            return toast.error("Data Not Found !");
                          toast
                            .promise(
                              POSTAPI(Api.AddUserCSV, {
                                data: newData?.map((item) => {
                                  let { _id, ...newRow } = item;
                                  return newRow;
                                }),
                              }),
                              {
                                loading: "Wait Few Moment !",
                                success: "Users Add Successfully !",
                                error: "CSV Structure Not Match !",
                              }
                            )
                            .then(() => {
                              setisUpdate(!isUpdate);
                            })
                            .catch(() => {});
                        }}
                        className="btn btn-reset"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                        onClick={() => {
                          toast
                            .promise(
                              POSTAPI(Api.AddUserCSV, {
                                data: ImportData?.map((item) => {
                                  let { _id, ...newRow } = item;
                                  return newRow;
                                }),
                              }),
                              {
                                loading: "Wait Few Moment !",
                                success: "Users Add Successfully !",
                                error: "CSV Structure Not Match !",
                              }
                            )
                            .then(() => {
                              setisUpdate(!isUpdate);
                            })
                            .catch(() => {});
                        }}
                        data-bs-dismiss="modal"
                        className="btn btn-upload me-0"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exportUserDetail"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Export User Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <div>
                    <div>
                      <h4 className="py-4 mx-auto ">
                        Are sure to want export User Detail !
                      </h4>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button
                        id="closeEdit"
                        className="btn btn-reset"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                        onClick={() => {
                          document.getElementById("exportUser").click();
                        }}
                        data-bs-dismiss="modal"
                        className="btn btn-upload me-0"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          aria-labelledby="deleteConfirmationModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title text-white"
                  id="deleteConfirmationModalLabel"
                >
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4 className="py-4 mx-auto">
                  Are you sure you want to delete this user?
                </h4>
                <div className="d-flex justify-content-center pt-2">
                  <button
                    id="confirmDeleteButton"
                    className="btn btn-primary me-2"
                    data-bs-dismiss="modal"
                    onClick={confirmDelete}
                  >
                    Yes
                  </button>
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default UserDetails;
