import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Piechart from "./Piechart";
import { DashboardData, GetRevenueApi } from "../helper/integration";
import { Api } from "../utils/Api";
const color = ["#71dd37", "#c256fa", "#8592a3"];

const Skeleton = () => {
  return (
    <>
      <p
        className="card-title skeleton mb-2 py-2  "
        style={{ width: "40px", height: "40px" }}
      ></p>
      <p
        className="card-title skeleton"
        style={{ width: "150px", height: "20px" }}
      ></p>
    </>
  );
};

const Dashboard = () => {
  const [ChartInfo, setChartInfo] = useState(null);
  const [AllDetail, setAllDetail] = useState(null);
  const [revenue, setRevenue] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  async function getDashboardInfo() {
    try {
      const data = await DashboardData();
      setAllDetail(data);
      console.log(data);

      // Sample data

      // Extract _id and total values
      const totals = data?.ChartInfo?.map((item) => item.total);

      // Calculate total count
      const totalCount = totals.reduce((acc, curr) => acc + curr, 0);

      // Calculate percentages
      const percentages = totals.map((total, index) => {
        let totalPer = ((total / totalCount) * 100).toFixed(1);
        let obj = { ...data?.ChartInfo[index], totalPer };
        return obj;
      });
      setChartInfo(percentages);
      setisLoad(true);
    } catch (er) {
      console.log(er);
    }
  }

  const fetchRevenueData = async () => {
    try {
      const { LastSevenDaysRevenue, DayWise } = await GetRevenueApi();
      setRevenue(LastSevenDaysRevenue); // Assuming DayWise is an array of revenue data
      console.log(DayWise, LastSevenDaysRevenue); // Log the data received
      setisLoad(true); // Update load state to true
    } catch (error) {
      console.error("Error fetching Revenue data:", error);
    }
  };

  useEffect(() => {
    fetchRevenueData();
    getDashboardInfo();

    //   (async function () {
    //     try {
    //       const { data } = await GetRevenueApi();
    //       setRevenue(data.data);
    //       console.log(data.data);
    //       //setVehicles(currentRecords);
    //     } catch (er) {
    //       console.log(er);
    //     }
    //   })();
  }, []);

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-24">
                <div className="bg-box ">
                  <div className="pro-add-new px-0">
                    {isLoad ? (
                      <p>TOTAL ORDERS</p>
                    ) : (
                      <p
                        className="card-title skeleton"
                        style={{ height: "22px", width: "150px" }}
                      ></p>
                    )}
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-7">
                      {isLoad ? (
                        <Piechart
                          ChartNames={ChartInfo?.map((item) => item?._id)}
                          ChartValues={ChartInfo?.map((item) => item.total)}
                        />
                      ) : (
                        <div className="chart skeleton"></div>
                      )}
                    </div>
                    <div className="col-lg-5 col-5">
                      <div>
                        {isLoad
                          ? ChartInfo?.map((item, index) => {
                              return (
                                <div className="project-one" key={index * 6}>
                                  <div className="project-photo-discription">
                                    <span
                                      className="completed"
                                      style={{ backgroundColor: color[index] }}
                                    ></span>
                                    <div className="pro-title-disc">
                                      <h5>{item?._id}</h5>
                                      <p className="mb-0">{item?.totalPer}%</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : [...Array(3).keys()].map((item) => {
                              return (
                                <div className="project-one">
                                  <div className="project-photo-discription">
                                    <span className="status-icon skeleton"></span>
                                    <div className="pro-title-disc">
                                      <p className="card-title skeleton"></p>
                                      <p
                                        className="card-title skeleton"
                                        style={{ width: "50px" }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-4 col-sm-6 col-12 mb-24">
                  {/* <Link to={"/Totalbooking"}> */}
                  <div className="bg-box">
                    <div className="welcome-section">
                      <div className="welcome-text">
                        {isLoad ? (
                          <>
                            <h5>{AllDetail?.TotalBooking}</h5>
                            <p>Total Bookings</p>
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 col-12 mb-24">
                  {/* <Link to={"/Completebooking"}> */}
                  <div className="bg-box">
                    <div className="welcome-section">
                      <div className="welcome-text">
                        {isLoad ? (
                          <>
                            <h5>
                              {ChartInfo?.map((item) => {
                                if (item?._id == "Complete") {
                                  //Completed
                                  return item?.total;
                                }
                              })}
                            </h5>
                            <p>Complete Bookings</p>
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 col-12 mb-24">
                  {/* <Link to={"/Refunded"}> */}
                  <div className="bg-box">
                    <div className="welcome-section">
                      <div className="welcome-text">
                        {isLoad ? (
                          <>
                            <h5>
                              {ChartInfo?.map((item) => {
                                if (item?._id == "Accept") {
                                  return item?.total;
                                }
                              })}
                            </h5>
                            <p>Refunded</p>
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 col-12 mb-24">
                  {/* <Link to={"/Pending"}> */}
                  <div className="bg-box">
                    <div className="welcome-section">
                      <div className="welcome-text">
                        {isLoad ? (
                          <>
                            <h5>
                              {ChartInfo?.map((item) => {
                                if (item?._id == "Pending") {
                                  return item?.total;
                                }
                              })}
                            </h5>
                            <p>Pending</p>
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NewUser"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>{AllDetail?.TodaysUsers}</h5>
                        <p>New Users</p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/UserDetails"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>{AllDetail?.TotalUser}</h5>
                        <p>No Of Users</p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/TotalNoOfEvents"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>{AllDetail?.TotalDrive}</h5>
                        <p>No Of Drivers</p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NoOfMatchesDashboard"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>{AllDetail?.TotalNewDriveRequest}</h5>
                        <p>New Driver Request</p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NoOfGuestlistSignups"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>{AllDetail?.TodayBookingTotal}</h5>
                        <p>Today's Bookings</p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
              {/* <Link to={"/NoOfVIPTableRequests"}> */}
              <div className="bg-box">
                <div className="welcome-section">
                  <div className="welcome-text">
                    {isLoad ? (
                      <>
                        <h5>${revenue}</h5>
                        <p>
                          Revenue{" "}
                          <b>
                            ( Last seven days <span>${revenue}</span> )
                          </b>
                        </p>
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Dashboard;
