import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import user from "../Image/user.png";
import { useCookies } from "react-cookie";
import { ShowContentApi } from "../helper/integration";
import { Api } from "../utils/Api";
const Sidebar = () => {
  const [cookie, setcookie, removecookie] = useCookies(["admin"]);
  const [image, setImage] = useState("");
  const [UserData, setUserData] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUpdate, setisUpdate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookie.admin) window.location = "/";

    (async () => {
      //if (!cookie.admin) window.location = "/";
      try {
        let obj = {
          id: cookie.admin,
        };
        const data = await ShowContentApi(Api.AdminShow, obj);
        setUserData((prev) => data);
        setisUpdate(!isUpdate);
        //setImage(data?.image);
        //setisLoad(false);
      } catch (er) {
        console.log(er);
      }
    })();
  }, [cookie, setUserData]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown open/close state
  };

  const handleLogout = () => {
    removecookie("admin");
    navigate("/");
  };

  const location = useLocation();

  return (
    <div>
      {/* nav fixx */}
      <div className="sidebar">
        <div className="sidebar-logo">
          <Link to={"/Dashboard"}>
            <img src={logo} className="img-fluid" />
          </Link>
        </div>
        <div className="overflow-y">
          <div className="sidebar-link">
            <ul>
              <li
                className={location.pathname === "/Dashboard" ? "active" : ""}
              >
                <NavLink to={"/Dashboard"}>
                  <i className="fa-solid fa-house"></i>Dashboard
                </NavLink>
              </li>
              <li
                className={location.pathname === "/UserDetails" ? "active" : ""}
              >
                <NavLink to={"/UserDetails"}>
                  <i className="fa-solid fa-user"></i>User Details
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/DriverDetails" ? "active" : ""
                }
              >
                <NavLink to={"/DriverDetails"}>
                  <i className="fa-solid fa-user"></i>Driver Details
                </NavLink>
              </li>
              <li className={location.pathname === "/Bookings" ? "active" : ""}>
                <NavLink to={"/Bookings"}>
                  <i className="fa-solid fa-user"></i>Bookings
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/DriverDocuments" ? "active" : ""
                }
              >
                <NavLink to={"/DriverDocuments"}>
                  <i className="fa-solid fa-user"></i>Driver Documents
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/VehicleTypes" ? "active" : ""
                }
              >
                <NavLink to={"/VehicleTypes"}>
                  <i className="fa-solid fa-user"></i>Vehicle Types
                </NavLink>
              </li>
              <li
                className={location.pathname === "/PromoCode" ? "active" : ""}
              >
                <NavLink to={"/PromoCode"}>
                  <i className="fa-solid fa-user"></i>Promo Code
                </NavLink>
              </li>
              <li
                className={location.pathname === "/BirdEyeView" ? "active" : ""}
              >
                <NavLink to={"/BirdEyeView"}>
                  <i className="fa-solid fa-user"></i>Bird eye view
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/PromotionBanner" ? "active" : ""
                }
              >
                <NavLink to={"/PromotionBanner"}>
                  <i className="fa-solid fa-user"></i>Promotion Banner
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/Transactions" ? "active" : ""
                }
              >
                <NavLink to={"/Transactions"}>
                  <i className="fa-solid fa-user"></i>Transactions
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/Notification" ? "active" : ""
                }
              >
                <NavLink to={"/Notification"}>
                  <i className="fa-solid fa-user"></i>Notification
                </NavLink>
              </li>
              <li className={location.pathname === "/FAQ" ? "active" : ""}>
                <NavLink to={"/FAQ"}>
                  <i className="fa-solid fa-user"></i>FAQ
                </NavLink>
              </li>
              <div className="page-line">
                <hr />
                <span>Dispatcher</span>
              </div>
              <li
                className={location.pathname === "/NewRequest" ? "active" : ""}
              >
                <NavLink to={"/NewRequest"}>
                  <i className="fa-solid fa-user"></i>New Request
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/CurrentRequest" ? "active" : ""
                }
              >
                <NavLink to={"/CurrentRequest"}>
                  <i className="fa-solid fa-user"></i>Current Request
                </NavLink>
              </li>
              <li
                className={location.pathname === "/Statistics" ? "active" : ""}
              >
                <NavLink to={"/Statistics"}>
                  <i className="fa-solid fa-user"></i>Statistics
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/AddNewRequest" ? "active" : ""
                }
              >
                <NavLink to={"/AddNewRequest"}>
                  <i className="fa-solid fa-user"></i>Add New Request
                </NavLink>
              </li>
              <div className="page-line">
                <hr />
                <span>Help & Support</span>
              </div>
              <li
                className={
                  location.pathname === "/DisputeSupport" ? "active" : ""
                }
              >
                <NavLink to={"/DisputeSupport"}>
                  <i className="fa-solid fa-user"></i>Dispute Support
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/PaymentSupport" ? "active" : ""
                }
              >
                <NavLink to={"/PaymentSupport"}>
                  <i className="fa-solid fa-user"></i>Payment Support
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/OtherSupport" ? "active" : ""
                }
              >
                <NavLink to={"/OtherSupport"}>
                  <i className="fa-solid fa-user"></i>Other Support
                </NavLink>
              </li>
              <div className="page-line">
                <hr />
                <span>Content Management</span>
              </div>
              <li className={location.pathname === "/AboutUs" ? "active" : ""}>
                <NavLink to={"/AboutUs"}>
                  <i className="fa-solid fa-user"></i>About Us
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/PrivacyPolicy" ? "active" : ""
                }
              >
                <NavLink to={"/PrivacyPolicy"}>
                  <i className="fa-solid fa-user"></i>Privacy Policy
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/TermsConditions" ? "active" : ""
                }
              >
                <NavLink to={"/TermsConditions"}>
                  <i className="fa-solid fa-user"></i>Terms and Conditions
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fixx">
        <nav className="top-header navbar navbar-expand-lg sticky-top navbar-light">
          <div className="py-2 container-fluid">
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control border-0"
                placeholder="Search"
              />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navheight justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav navbarbottom mb-lg-0 align-items-lg-center">
                {/* <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                                    <Link className="nav-link hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                        <NavLink className="nav-link bell" aria-current="page" to={"#"} ><i className="fa-regular fa-bell"></i></NavLink>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end py-0" data-bs-popper="static">
                                        <li className="dropdown-menu-header border-bottom">
                                            <div className="dropdown-header d-flex align-items-center py-3">
                                                <h5 className="text-body mb-0 me-auto">Notification</h5>
                                                <Link to={"#"} className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Mark all as read" data-bs-original-title="Mark all as read"><i className="fa-regular fa-envelope-open"></i></Link>
                                            </div>
                                        </li>
                                        <li className="dropdown-notifications-list scrollable-container ps ps--active-y">
                                            <ul className="list-group list-group-flush all-project-table">
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                                                            <p className="mb-0">Won the monthly best seller gold badge</p>
                                                            <small className="text-muted">1h ago</small>
                                                        </div>
                                                        <div className="flex-shrink-0 dropdown-notifications-actions">
                                                            <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                            <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Charles Franklin</h6>
                                                        <p className="mb-0">Accepted your connection</p>
                                                        <small className="text-muted">12hr ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New Message ✉️</h6>
                                                        <p className="mb-0">You have new message from Natalie</p>
                                                        <small className="text-muted">1h ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Whoo! You have new order 🛒 </h6>
                                                        <p className="mb-0">ACME Inc. made new order $1,154</p>
                                                        <small className="text-muted">1 day ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Application has been approved 🚀 </h6>
                                                        <p className="mb-0">Your ABC project application has been approved.</p>
                                                        <small className="text-muted">2 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Monthly report is generated</h6>
                                                        <p className="mb-0">July monthly financial report is generated </p>
                                                        <small className="text-muted">3 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Send connection request</h6>
                                                        <p className="mb-0">Peter sent you connection request</p>
                                                        <small className="text-muted">4 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New message from Jane</h6>
                                                        <p className="mb-0">Your have new message from Jane</p>
                                                        <small className="text-muted">5 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-1">CPU is running high</h6>
                                                            <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                                                            <small className="text-muted">5 days ago</small>
                                                        </div>
                                                        <div className="flex-shrink-0 dropdown-notifications-actions">
                                                            <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                            <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                        </div>
                                                    </div>
                                                </li>   
                                            </ul>
                                        </li>
                                        <li className="dropdown-menu-footer border-top p-3">
                                            <button className="btn add-new text-uppercase w-100">view all notifications</button>
                                        </li>
                                    </ul>
                                </li> */}
                <li className="nav-item dropdown">
                  <button
                    type="button"
                    className="navbar-user position-relative border-0 bg-transparent"
                    onClick={handleDropdownToggle} // Handle the toggle manually
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen ? "true" : "false"} // Use state for open/close
                  >
                    <img src={UserData?.image || user} alt="User" />
                    <span className="position-absolute bottom-0 start-100 translate-middle p-1 bg-light-green border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>
                  </button>
                  <div
                    className={`dropdown-menu dropdown left-drop ${
                      isDropdownOpen ? "show" : ""
                    } dropdown-adjust`}
                  >
                    <Link className="dropdown-item" to="/Profile">
                      Profile
                    </Link>
                    <Link className="dropdown-item" to="/ChangePassword">
                      Change Password
                    </Link>
                    <button className="dropdown-item" onClick={handleLogout}>
                      LogOut
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
