import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { RemoveSupport, ShowSupport } from "../../helper/integration";
import { Api } from "../../utils/Api";
import Paggination from "../../common/Paggination";
import toast from "react-hot-toast";
import NotFound from "../../Image/notfound.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const Driver = () => {
  const [MainData, setMainData] = useState([]);
  const [ListData, setListData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = MainData?.filter(
      (item) =>
        (item.types === "Driver" &&
          item?.username?.toLowerCase()?.includes(val?.toLowerCase())) ||
        item?.mobilenumber?.toLowerCase()?.includes(val?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(val?.toLowerCase()) ||
        item?.descriptions?.toLowerCase()?.includes(val?.toLowerCase())
    );

    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setListData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleDeleteSupport = (id) => {
    setItemIdToDelete(id); // Set the item ID to delete
    //setDeleteModalVisible(true);
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show();
  };
  const confirmDelete = () => {
    if (itemIdToDelete) {
      toast
        .promise(RemoveSupport(Api.DeleteDispute, { id: itemIdToDelete }), {
          loading: "Wait Few Moment !",
          success: "Dispute Delete Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
        })
        .catch(() => {});
    }
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = MainData.slice(indexOfFirstRecord, indexOfLastRecord);
    setListData(currentRecords);
  };

  useEffect(() => {
    (async function () {
      try {
        const data = await ShowSupport(Api.ShowDispute);
        const filteredData = data.filter((item) => item.types === "Driver");
        setMainData(filteredData);
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = filteredData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(filteredData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  return (
    <div>
      {<Sidebar />}
      <div className="">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>DRIVER DISPUTE SUPPORT</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleSearch}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Email ID</th>
                        <th>Reason Of Dispute</th>
                        <th>Description</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center  "
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData?.filter((item) => item.types === "Driver").map(
                          (item, index) => {
                            return (
                              <tr>
                                <td>
                                  {currentPage == 1
                                    ? index + 1
                                    : index + 1 + currentPage * 10 - 10}
                                </td>
                                <td>{item?.username}</td>
                                <td>{item?.mobilenumber}</td>
                                <td>{item?.email}</td>
                                <td>{item?.reason ?? "-"}</td>
                                <td>{item?.descriptions ?? "-"}</td>
                                <td>{item?.createdAt ?? "-"}</td>
                                <td>
                                  <div className="icon-up-del">
                                    <Link
                                      onClick={() =>
                                        handleDeleteSupport(item?._id)
                                      }
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column  mt-2 justify-content-center align-items-center  "
                              style={{ height: "15vh" }}
                            >
                              <img
                                src={NotFound}
                                style={{ height: "100px" }}
                                alt=""
                              />
                              <div>Dispute Support not found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* Delete Confirmation Modal */}
                <div
                  className="modal fade"
                  id="deleteConfirmationModal"
                  tabIndex="-1"
                  aria-labelledby="deleteConfirmationModalLabel"
                  aria-hidden="true"
                  //style={{ visibility: "visible" }} // For debugging
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header bg-primary">
                        <h5
                          className="modal-title text-white"
                          id="deleteConfirmationModalLabel"
                        >
                          Confirm Deletion
                        </h5>
                        <button
                          type="button"
                          className="btn-close bg-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <h4 className="py-4 mx-auto">
                          Are you sure you want to delete this driver dispute ?
                        </h4>
                        <div className="d-flex justify-content-center pt-2">
                          <button
                            className="btn btn-primary me-2"
                            data-bs-dismiss="modal"
                            onClick={confirmDelete}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driver;
