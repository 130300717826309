import { Api } from "../utils/Api";
import axios from "axios";

export const LoginApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api?.LoginApi, obj);
      resolve(data.data);
      console.log(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

// Users

export const GetUsersApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api?.ShowUsersApi);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const EditUserApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api?.EditUser, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const RemoveUser = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DeleteUser, obj);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const DashboardData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DashboardApi);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

// Driver

export const ShowDriversApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowDriver);
      resolve(data.data);
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const DriverStatusChange = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DriverStatus, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const AddDriverApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AddDriver, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const RemoveDriverApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.RemoveDriver, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const UpdateDriverApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.UpdateDriver, obj);
      console.log(data);
      resolve();
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

// Booking Data

export const ShowBookingApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowBooking);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const RemoveBookingApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.RemoveBooking, obj);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowDriverDocApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowDriverDoc);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const VerifyDriverApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.VerifyDriver, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowBannerApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowBanner);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const ChangeBannerStatusApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ChangeBannerStatus, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const AddBannerApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AddBanner, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const DeleteBannerApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DeleteBanner, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const UpdateBannerApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.UpdateBanner, obj);
      resolve();
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};

export const ShowNotificationApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowNotification);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const AddNotificationApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AddNotification, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const RemoveNotificationApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.RemoveNotification, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const AddFAQApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AddFAQ, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowFAQApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowFAQ, obj);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const DeleteFAQApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.RemoveFAQ, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const UpdateFAQApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.UpdateFAQ, obj);
      resolve();
    } catch (error) {
      reject();
    }
  });
};

export const ShowSupport = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const RemoveSupport = (url, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowContentApi = (url, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url, obj);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const UpdateContent = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.UpdateContent, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject();
    }
  });
};

export const UpdateAdminProfile = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AdminProfileUpdate, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resolve();
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const AdminUpdateApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AdminUpdate, obj);
      resolve();
    } catch (er) {
      console.error("Error updating admin profile:", er);
      reject(er);
    }
  });
};

export const ChangeAdminPasswordApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AdminChangePassword, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const ForgotAdminPasswordApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AdminForgotPassword, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowFilterWiseBookApi = (obj = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowFilterWiseBook, obj);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const POSTAPI = (url, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url, obj);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const AdminEmailLinkApi = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new URLSearchParams();
      formData.append("email", email);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const { data } = await axios.post(Api.AdminEmailLink, formData, config); // Adjust the endpoint accordingly
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const GetRevenueApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowRevenue);
      resolve(data.data);
      console.log(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const AddDispatchRequestApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        Api.AddNewDispatchRequest,
        JSON.stringify(obj),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json", // Optional but recommended
          },
        }
      );
      console.log(data.data);
      resolve(data.data);
      //console.log(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const RemovePendingBookingApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.RemoveBooking, obj);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const ShowNewRequestsApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ShowNewRequests);
      resolve(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

export const UpdateRequestApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        Api.EditNewRequests,
        JSON.stringify(obj),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json", // Optional but recommended
          },
        }
      );
      console.log(data);
      resolve();
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};

export const AddBookingpi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        Api.AddBooking,
        //JSON.stringify(obj),
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json", // Optional but recommended
          },
        }
      );
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const EditBookingApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.EditBooking, obj, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json", // Optional but recommended
        },
      });
      resolve(data); // Resolve with the data from the response if needed
    } catch (er) {
      reject(er);
    }
  });
};
