import React from "react";
import Chart from "react-apexcharts";

const Piechart = ({ ChartNames = [], ChartValues = [] }) => {
  const options = {
    labels: ChartNames,
    colors: ["#71dd37", "#c256fa", "#8592a3"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              color: undefined,
            },
            value: {
              show: true,
              fontSize: "25px",
              fontWeight: 700,
              color: "rgba(50, 71, 92, 0.87)",
              offsetY: 16,
            },
            total: {
              show: true,
              label: "Total Orders",
              color: "rgba(50, 71, 92, 0.38)",
              fontSize: "18px",
              fontWeight: 300,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div className="donut">
      <Chart options={options} series={ChartValues} type="donut" width="100%" />
    </div>
  );
};

export default Piechart;
