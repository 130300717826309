import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { EditUserApi } from "../../helper/integration";
import { useForm } from "react-hook-form";

const EditUser = ({ item, isUpdate, setisUpdate, setItem }) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [ImageFile, setImageFile] = useState(null);
  const handleUpdateUser = async (data) => {
    if (item) {
      const newData = new FormData();
      newData.append("user_id", item?._id);
      newData.append("username", data?.username || item?.username);
      newData.append("mobilenumber", data?.mobilenumber || item?.mobilenumber);
      newData.append("email", data?.email || item?.email);
      newData.append("refercode", data?.refercode || item?.refercode);

      if (ImageFile) newData.append("image", ImageFile);
      setItem(null);

      setImageFile(null);
      await toast.promise(EditUserApi(newData), {
        loading: "Wait Few Moment !",
        success: "User Update Successfully !",
        error: "Something Wrong !",
      });

      document.getElementById("closeEdit").click();
      reset();
      setisUpdate(!isUpdate);
    }
  };
  return (
    <div
      className="modal fade"
      id="exampleModaledit"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit User Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="user-details">
              <form onSubmit={handleSubmit(handleUpdateUser)}>
                <div className="mb-3">
                  <label className="mb-2">Profile Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setImageFile(e.target.files[0])}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.username}
                    {...register("username")}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.mobilenumber}
                    {...register("mobilenumber")}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="mail"
                    className="form-control"
                    defaultValue={item?.email}
                    {...register("email")}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Refer Code</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item?.refercode}
                    {...register("refercode")}
                  />
                </div>
                {/* <div className="form-floating mb-3 mt-2">
                            <select className="form-select form-control" id="floatingSelectGrid" aria-label="Floating label select example">
                                <option selected>OPIUM</option>
                                <option value="1">Pacha</option>
                                <option value="2">Shoko</option>
                                <option value="3">Downtown</option>
                                <option value="3">Twenties</option>
                            </select>
                            <label for="floatingSelectGrid">Venue</label>
                        </div> */}
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    id="closeEdit"
                    className="btn btn-reset"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-upload me-0">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
